/* eslint-disable max-len */
import type { SVGAttributes } from 'react';
import React from 'react';

export function GitHubIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8 0C3.58267 0 0 3.67255 0 8.20224C0 11.8263 2.292 14.9007 5.47133 15.9855C5.87067 16.0614 6 15.8071 6 15.5911V14.0641C3.77467 14.5603 3.31133 13.0962 3.31133 13.0962C2.94733 12.1482 2.42267 11.896 2.42267 11.896C1.69667 11.3868 2.478 11.3977 2.478 11.3977C3.28133 11.4551 3.704 12.2432 3.704 12.2432C4.41733 13.4968 5.57533 13.1345 6.032 12.9247C6.10333 12.395 6.31067 12.0327 6.54 11.8283C4.76333 11.6198 2.89533 10.9165 2.89533 7.77436C2.89533 6.87826 3.208 6.1469 3.71933 5.57274C3.63667 5.36563 3.36267 4.53106 3.79733 3.40188C3.79733 3.40188 4.46933 3.18179 5.998 4.24261C6.636 4.06079 7.32 3.96989 8 3.96647C8.68 3.96989 9.36467 4.06079 10.004 4.24261C11.5313 3.18179 12.202 3.40188 12.202 3.40188C12.6373 4.53174 12.3633 5.36632 12.2807 5.57274C12.794 6.1469 13.104 6.87895 13.104 7.77436C13.104 10.9247 11.2327 11.6185 9.45133 11.8215C9.738 12.0758 10 12.5747 10 13.3403V15.5911C10 15.8091 10.128 16.0655 10.534 15.9848C13.7107 14.8987 16 11.8249 16 8.20224C16 3.67255 12.418 0 8 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
