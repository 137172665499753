import { Button, Box, useDisclosure, Modal, ModalContent } from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';

import SearchBar from '../searchBar/SearchBar';

export const Search = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onToggle}
        variant=""
        padding={1}
        _hover={{ transform: 'scale(1.1)' }}
      >
        <IconSvg name="search" boxSize="16px" color="accentGray" />

      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalContent
          position="fixed"
          top="80px"
          right="150px"
          padding={0}
        >
          <Box
            w="100%"
            backgroundColor="lightGray"
            borderRadius="24px"
            padding={{ base: '24px', lg: '24px' }}
            data-label="hero plate"
          >
            <SearchBar isHomepage />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
