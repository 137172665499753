import { hybridTestnet } from 'configs/hybrid/hybrid';

import { getEnvValue } from './utils';

const walletConnectId = getEnvValue('NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID');
const targetNetworks = [hybridTestnet];

const wagmiWallet = Object.freeze({
  walletConnectId,
  targetNetworks,
});

export default wagmiWallet;
