import { getDefaultConfig, getDefaultWallets } from '@rainbow-me/rainbowkit';
import type { Config } from 'wagmi';

import config from 'configs/app';

import '@rainbow-me/rainbowkit/styles.css';
import { hybridTestnet } from './hybrid';

export const TARGET_NETWORKS = [hybridTestnet];

const { wallets } = getDefaultWallets();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wagmiConfig: Config = getDefaultConfig<any, any>({
  appName: 'Hybrid',
  projectId: config.wagmiWallet.walletConnectId ?? '',
  wallets: wallets,
  chains: TARGET_NETWORKS,
  ssr: true,
});
