/* eslint-disable max-len */
import type { SVGAttributes } from 'react';
import React from 'react';

export function XIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M15.473 4.00002C14.9597 4.23335 14.4064 4.38669 13.833 4.46002C14.4197 4.10669 14.873 3.54669 15.0864 2.87335C14.533 3.20669 13.9197 3.44002 13.273 3.57335C12.7464 3.00002 12.0064 2.66669 11.1664 2.66669C9.5997 2.66669 8.3197 3.94669 8.3197 5.52669C8.3197 5.75335 8.34637 5.97335 8.39303 6.18002C6.0197 6.06002 3.90637 4.92002 2.4997 3.19335C2.25303 3.61335 2.11303 4.10669 2.11303 4.62669C2.11303 5.62002 2.61303 6.50002 3.38637 7.00002C2.91303 7.00002 2.47303 6.86669 2.08637 6.66669V6.68669C2.08637 8.07335 3.07303 9.23335 4.3797 9.49335C3.96019 9.60816 3.51976 9.62413 3.09303 9.54002C3.2741 10.1083 3.62873 10.6056 4.10704 10.962C4.58536 11.3183 5.16333 11.5158 5.7597 11.5267C4.74879 12.327 3.4957 12.7596 2.20637 12.7534C1.9797 12.7534 1.75303 12.74 1.52637 12.7134C2.79303 13.5267 4.2997 14 5.91303 14C11.1664 14 14.053 9.64002 14.053 5.86002C14.053 5.73335 14.053 5.61335 14.0464 5.48669C14.6064 5.08669 15.0864 4.58002 15.473 4.00002Z"
        fill="currentColor"
      />
    </svg>
  );
}
