import { Flex, Heading, Link } from '@chakra-ui/react';
import React from 'react';

import { GitHubIcon } from 'icons/social/GitHub';
import { TelegramIcon } from 'icons/social/Telegram';
import { XIcon } from 'icons/social/X';

export const HybLinks = () => {
  return (
    <Flex
      direction="column"
      gap={4}
      width="100%"
    >
      <Heading
        color="#9599AB"
        fontSize="14px"
        lineHeight="20px"
        fontWeight="700"
        height="fit-content"
      >
        Need help?
      </Heading>
      <Link
        display="flex"
        justifyContent="space-between"
        width="100%"
        color="#0C0C0D"
        fontWeight="700"
        fontSize="16px"
        lineHeight="21px"
        href="#">
        Contact us
      </Link>
      <Link
        display="flex"
        justifyContent="space-between"
        width="100%"
        color="#0C0C0D"
        fontWeight="700"
        fontSize="16px"
        lineHeight="21px"
        href="#">
        FAQs
      </Link>

      <Flex
        mt="auto"
        justifyContent="center"
        direction="column"
        width="100%"
      >
        <Flex borderBottom="1px solid #EAECF5" my={4} />

        <Flex gap={12} justifyContent="center">
          <Link
            href="https://x.com/buildonhybrid"
            target="_blank"
            color="#0C0C0D"
            rel="noopener noreferrer"
            aria-label="Twitter (X)"
          >
            <XIcon />
          </Link>
          <Link
            href="https://t.me/HybridAnnouncements"
            target="_blank"
            color="#0C0C0D"
            rel="noopener noreferrer"
            aria-label="Telegram"
          >
            <TelegramIcon />
          </Link>
          <Link
            href="https://github.com/buildonhybrid"
            target="_blank"
            color="#0C0C0D"
            rel="noopener noreferrer"
            aria-label="GitHub"
          >
            <GitHubIcon />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
