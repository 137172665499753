import type { SVGAttributes } from 'react';
import React from 'react';

export function TriangleDropdownIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <path d="M9 12.5L5.10289 8L12.8971 8L9 12.5Z" fill="currentColor" />
    </svg>
  );
}
