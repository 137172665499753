import type { SystemStyleInterpolation } from '@chakra-ui/styled-system';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';

const baseStyle = defineStyle(getDefaultTransitionProps());

const variantPrimary = defineStyle((props) => {
  return {
    color: 'dark',
    _hover: {
      color: 'accent',
      textDecorationStyle: props.textDecorationStyle || 'solid',
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const variantSecondary = defineStyle((props) => {
  return {
    color: 'dark',
    _hover: {
      color: 'accent',
    },
  };
});

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
  secondary: variantSecondary,
};

const defaultProps = {
  variant: 'primary',
};

const Link = defineStyleConfig({
  variants,
  defaultProps,
  baseStyle,
});

export default Link;
