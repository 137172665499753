import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Wallet } from '../Wallet/Wallet';
import { HybridDropDown } from './HybridDropDown';
import { NavDesktop } from './NavDesktop';
import { Search } from './Search';

const TopBar = () => {
  return (
    <Flex
      px={ 7 }
      height="70px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #EAECF5"
      display={{ base: 'none', lg: 'flex' }}
    >
      <HybridDropDown />

      <Flex mx="auto" height="100%">
        <NavDesktop />
      </Flex>

      <Flex alignItems="center" gap={2}>
        <Search />
        <Flex width="1px" height="30px" backgroundColor="#EAECF5" />
        <Wallet />
      </Flex>

    </Flex>
  );
};

export default React.memo(TopBar);
