/* eslint-disable max-len */
import type { SVGAttributes } from 'react';
import React from 'react';

export function TelegramIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.3071 2.43614C14.3071 2.43614 15.6021 1.93114 15.4938 3.15747C15.4581 3.66247 15.1344 5.43014 14.8824 7.3418L14.0191 13.0051C14.0191 13.0051 13.9471 13.8348 13.2994 13.9791C12.6521 14.1231 11.6808 13.4741 11.5008 13.3298C11.3568 13.2215 8.80276 11.5981 7.90343 10.8048C7.65143 10.5881 7.36343 10.1555 7.93943 9.65047L11.7164 6.04314C12.1481 5.61047 12.5798 4.60047 10.7811 5.8268L5.74443 9.25347C5.74443 9.25347 5.16876 9.61447 4.08976 9.2898L1.7511 8.56814C1.7511 8.56814 0.887765 8.02714 2.36276 7.48614C5.96043 5.7908 10.3854 4.05947 14.3064 2.43614H14.3071Z"
        fill="currentColor"
      />
    </svg>
  );
}
