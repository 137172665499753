import {
  Flex, Link, Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { TriangleDropdownIcon } from 'icons/TriangleDropdown';
import useNavItems, { isGroupItem } from 'lib/hooks/useNavItems';

import NavLink from '../navigation/NavLink';
import NavLinkGroupDesktop from '../navigation/NavLinkGroupDesktop';

const BASE_URL = config.app.HYBRID_BASE_URL;

const DropDownLink = () => {
  const { mainNavItems } = useNavItems();
  return (
    <Popover
      trigger="hover"
      placement="bottom-start"
      isLazy
    >
      <PopoverTrigger>
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#000000"
          borderBottom="2px solid #76E6AD"
          fontWeight={700}
          href="/"
          _hover={{
            textDecoration: 'none',
          }}
        >
          Hybridscan
          <TriangleDropdownIcon />
        </Link>
      </PopoverTrigger>
      <PopoverContent
        width="fit-content"
        top={0}
      >
        <PopoverBody p={4}>
          {mainNavItems.map((item) => {
            if (isGroupItem(item)) {
              return <NavLinkGroupDesktop key={item.text} item={item} isCollapsed={ false } />;
            } else {
              return <NavLink key={item.text} item={item} isCollapsed={ false } />;
            }
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const NavDesktop = () => {
  return (
    <nav>
      <Flex gap={{ base: 6, lg: 8, xl: 12 }} height="100%">
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
          href={`${BASE_URL}/faucet`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Faucet
        </Link>
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
          href={`${BASE_URL}/wrapper`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Wrapper
        </Link>
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
          href={`${BASE_URL}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Atlas
        </Link>
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
          href={`${BASE_URL}/staking`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Staking
        </Link>
        <DropDownLink />
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          pointerEvents="none"
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
        >
          DEX
        </Link>
        <Link
          display="flex"
          height="100%"
          alignItems="center"
          color="#9599AB"
          fontWeight={700}
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          pointerEvents="none"
          transition="color .2s, border .2s"
          _hover={{
            textDecoration: 'none',
            color: '#000000',
            borderBottom: '2px solid #76E6AD',
          }}
        >
          Bridge
        </Link>
      </Flex>
    </nav >
  );
};
