import { Box, Flex, Drawer, DrawerOverlay, DrawerContent, DrawerBody, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import IconSvg from 'ui/shared/IconSvg';
import NavigationMobile from 'ui/snippets/navigation/NavigationMobile';
import NetworkLogo from 'ui/snippets/networkMenu/NetworkLogo';
import useNetworkMenu from 'ui/snippets/networkMenu/useNetworkMenu';

interface Props {
  isMarketplaceAppPage?: boolean;
}

const Burger = ({ isMarketplaceAppPage }: Props) => {
  const iconColor = useColorModeValue('gray.600', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const networkMenu = useNetworkMenu();

  const handleNetworkLogoClick = React.useCallback((event: React.SyntheticEvent) => {
    networkMenu.isOpen && event.preventDefault();
    networkMenu.onClose();
  }, [networkMenu]);

  return (
    <>
      <Box padding={2} onClick={onOpen} cursor="pointer">
        <IconSvg
          name="burger"
          boxSize={6}
          display="block"
          color={iconColor}
          aria-label="Menu button"
        />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth="260px">
          <DrawerBody p={6} display="flex" flexDirection="column">
            <Flex alignItems="center" justifyContent="space-between">
              <NetworkLogo onClick={handleNetworkLogoClick} />
            </Flex>
            <NavigationMobile onNavLinkClick={onClose} isMarketplaceAppPage={isMarketplaceAppPage} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Burger;
