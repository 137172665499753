/* eslint-disable quote-props */
import type { Chain } from 'viem';

export const hybridTestnet: Chain = {
  name: 'Hybrid Testnet',
  testnet: true,
  nativeCurrency: {
    decimals: 18,
    name: 'Hybrid',
    symbol: 'HYB',
  },
  id: 1224,
  rpcUrls: {
    public: { http: ['https://testnet-rpc.buildonhybrid.com'] },
    default: { http: ['https://testnet-rpc.buildonhybrid.com'] },
  },
  blockExplorers: {
    default: { name: 'Hybrid', url: 'https://explorer.buildonhybrid.com/' },
  },
};
