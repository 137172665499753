import { Flex, Popover, PopoverTrigger, PopoverContent, PopoverBody, useDisclosure, Button } from '@chakra-ui/react';
import React from 'react';

import { TriangleDropdownIcon } from 'icons/TriangleDropdown';

import { Logo } from '../header/Logo';
import { HybLinks } from './HybHelpLinks';

export const HybridDropDown = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Flex display={{ base: 'none', lg: 'flex' }}>
        <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start" isLazy>
          <PopoverTrigger>
            <Button
              onClick={onToggle}
              variant=""
              padding={0}
            >
              <Logo />
              <TriangleDropdownIcon
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                  marginLeft: '5px',
                }}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent w="236px" maxH="252px" overflowY="scroll">
            <PopoverBody px={5} py={5} boxShadow="2xl" >
              <Flex>
                <HybLinks />
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex display={{ base: 'flex', lg: 'none' }}>
        <Logo />
      </Flex>
    </>
  );
};
