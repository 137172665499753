import { Button, Flex, Text } from '@chakra-ui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import React from 'react';

import config from 'configs/app';
import { TriangleDropdownIcon } from 'icons/TriangleDropdown';
import { useConnectedWalletIcon } from 'lib/hooks/useConnectedWalletIcon';
import { formatAddress } from 'lib/utils';

const TARGET_NETWORKS = config.wagmiWallet.targetNetworks;

export const Wallet = () => {
  const walletIcon = useConnectedWalletIcon();

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openConnectModal,
        openChainModal,
        accountModalOpen,
      }) => {
        const handleClick = () => {
          if (account) {
            if (!TARGET_NETWORKS.some((c) => c.id === chain?.id)) {
              openChainModal();
              return;
            }

            openAccountModal();
          } else {
            openConnectModal();
          }
        };

        return (
          <Button
            onClick={handleClick}
            variant=""
            display="flex"
            gap={2}
            padding={1}
          >
            {account ? (
              <>
                <Image src={walletIcon} width={20} height={20} alt="wallet icon" />
                <Text display={{ base: 'none', lg: 'flex' }}>
                  {formatAddress(account?.address)}
                  <TriangleDropdownIcon
                    style={{
                      transform: accountModalOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </Text>
              </>
            ) : (
              <>
                <Text display={{ base: 'none', lg: 'flex' }}>Connect wallet</Text>
                <Flex display={{ base: 'flex', lg: 'none' }}>
                  <Image src={walletIcon} width={20} height={20} alt="wallet icon" />
                </Flex>
              </>
            )}
          </Button>
        );
      }}
    </ConnectButton.Custom>
  );
};
