import type { ChakraProps } from '@chakra-ui/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import React from 'react';
import { WagmiProvider } from 'wagmi';

import type { NextPageWithLayout } from 'nextjs/types';

import config from 'configs/app';
import { wagmiConfig } from 'configs/hybrid/wagmi';
import useQueryClientConfig from 'lib/api/useQueryClientConfig';
import { AppContextProvider } from 'lib/contexts/app';
import { ChakraProvider } from 'lib/contexts/chakra';
import { MarketplaceContextProvider } from 'lib/contexts/marketplace';
import { ScrollDirectionProvider } from 'lib/contexts/scrollDirection';
import { growthBook } from 'lib/growthbook/init';
import useLoadFeatures from 'lib/growthbook/useLoadFeatures';
import useNotifyOnNavigation from 'lib/hooks/useNotifyOnNavigation';
import { SocketProvider } from 'lib/socket/context';
import theme from 'theme';
import { FontsBase } from 'theme/Fonts';
import AppErrorBoundary from 'ui/shared/AppError/AppErrorBoundary';
import GoogleAnalytics from 'ui/shared/GoogleAnalytics';
import Layout from 'ui/shared/layout/Layout';
import Web3ModalProvider from 'ui/shared/Web3ModalProvider';

import 'lib/setLocale';
// import 'focus-visible/dist/focus-visible';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
}

const ERROR_SCREEN_STYLES: ChakraProps = {
  h: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: 'fit-content',
  maxW: '800px',
  margin: '0 auto',
  p: { base: 4, lg: 0 },
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {

  useLoadFeatures();
  useNotifyOnNavigation();

  const queryClient = useQueryClientConfig();

  const handleError = React.useCallback((error: Error) => {
    Sentry.captureException(error);
  }, []);

  const getLayout = Component.getLayout ?? ((page) => <Layout>{ page }</Layout>);

  return (
    <ChakraProvider theme={theme} cookies={pageProps.cookies}>
      <FontsBase />
      <AppErrorBoundary
        {...ERROR_SCREEN_STYLES}
        onError={handleError}
      >
        <WagmiProvider config={wagmiConfig}>
          <Web3ModalProvider>
            <AppContextProvider pageProps={pageProps}>
              <QueryClientProvider client={queryClient}>
                <RainbowKitProvider modalSize="compact" locale="en">
                  <GrowthBookProvider growthbook={growthBook}>
                    <ScrollDirectionProvider>
                      <SocketProvider url={`${config.api.socket}${config.api.basePath}/socket/v2`}>
                        <MarketplaceContextProvider>
                          {getLayout(<Component {...pageProps} />)}
                        </MarketplaceContextProvider>
                      </SocketProvider>
                    </ScrollDirectionProvider>
                  </GrowthBookProvider>
                </RainbowKitProvider>
                <ReactQueryDevtools buttonPosition="bottom-left" position="left" />
                <GoogleAnalytics />
              </QueryClientProvider>
            </AppContextProvider>
          </Web3ModalProvider>
        </WagmiProvider>
      </AppErrorBoundary>
    </ChakraProvider>
  );
}

export default MyApp;
