import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Logo as HybridLogo } from 'icons/Logo';
import { LogoSmallIcon } from 'icons/LogoSmall';

export const Logo = () => {
  return (
    <Flex
      gap={1}
    >
      <LogoSmallIcon height={42} width={42} color="#60FFAF" />
      <HybridLogo />
    </Flex>
  );
};
