import { Global } from '@emotion/react';
import React from 'react';

export const FontsBase = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Area Normal';
      font-weight: 900;
      src: url(/fonts/Area-Black.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 900;
      font-style: italic;
      src: url(/fonts/Area-BlackItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 800;
      src: url(/fonts/Area-Extrabold.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 800;
      font-style: italic;
      src: url(/fonts/Area-ExtraboldItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 700;
      src: url(/fonts/Area-Bold.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 700;
      font-style: italic;
      src: url(/fonts/Area-BoldItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 600;
      src: url(/fonts/Area-Semibold.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 600;
      font-style: italic;
      src: url(/fonts/Area-SemiboldItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 500;
      src: url(/fonts/Area-Medium.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 600;
      font-style: italic;
      src: url(/fonts/Area-MediumItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 400;
      src: url(/fonts/Area-Regular.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 400;
      font-style: italic;
      src: url(/fonts/Area-RegularItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 300;
      src: url(/fonts/Area-Light.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 300;
      font-style: italic;
      src: url(/fonts/Area-LightItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 200;
      src: url(/fonts/Area-Extralight.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 200;
      font-style: italic;
      src: url(/fonts/Area-ExtralightItalic.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 100;
      src: url(/fonts/Area-Thin.otf) format('opentype');
    }
    
    @font-face {
      font-family: 'Area Normal';
      font-weight: 100;
      src: url(/fonts/Area-ThinItalic.otf) format('opentype');
    }
    `}
  />
);
